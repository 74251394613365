<template>
  <div>
    <div class="return" v-if="type != 'order'" @click="back">
      <img class="backImg" src="@/assets/images/back.png" alt />
    </div>
    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />基本资料
        </div>
        <div style="text-align: right;">
          <el-button  v-if="detail.status != 3" type="danger" size="mini" class="mb10" plain @click="openDialog('修改订单')">修改订单</el-button>
          <el-button  v-if="detail.status != 4" type="danger" size="mini" class="mb10" plain @click="hint('del','作废此订单')">作废订单</el-button>
        </div>
      </div>
      <el-descriptions class="margin-top" :column="3" border>
        <el-descriptions-item>
          <template slot="label">销售部门</template>
          {{detail.shop_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">客户姓名</template>
          {{detail.consignee}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">护理师</template>
          {{detail.sale_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">业务类型</template>
          {{detail.business_type}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">业务二级分类</template>
          {{detail.business_two_type_text}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">项目类型</template>
          {{detail.project_type_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">订单类型</template>
          {{detail.order_type}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">销售组织</template>
          {{detail.sales_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">发货组织</template>
          {{detail.settlement_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">发货部门</template>
          {{detail.delivery_name}}
        </el-descriptions-item>
        <!-- <el-descriptions-item>
          <template slot="label">发货仓库</template>
          {{detail.house_code_name}}
        </el-descriptions-item> -->
        <el-descriptions-item>
          <template slot="label">市场</template>
          {{detail.market_name?detail.market_name:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">录单员</template>
          {{detail.operator_name?detail.operator_name:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">金蝶单号</template>
          {{detail.king_sale_no?detail.king_sale_no:'暂无'}}
        </el-descriptions-item>
         <el-descriptions-item>
          <template slot="label">是否体验</template>
          {{detail.is_experience==0?'否':'是'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">是否外联</template>
          {{detail.is_outreach==0?'否':'是'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label" >外联人</template>
          {{detail.outreahc_name?detail.outreahc_name:'无'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">是否转介绍</template>
          {{detail.is_introduction==0?'否':'是'}}
        </el-descriptions-item>
        <el-descriptions-item >
          <template slot="label">转介绍人</template>
          {{detail.introduction_name?detail.introduction_name:'无'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">创建时间</template>
          {{detail.create_time}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">销售合计</template>
          {{detail.total_amount}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">实收金额</template>
          {{detail.pay_amount}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">赠送金额</template>
          {{detail.gift_amount}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">店转金额</template>
          {{detail.shop_transfer_amount}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">订单状态</template>
          {{detail.status_msg}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">支付时间</template>
          {{detail.pay_time?detail.pay_time:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">成交时间</template>
          {{detail.deal_time?detail.deal_time:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item >
          <template slot="label">取消时间</template>
          {{detail.cancel_time?detail.cancel_time:'暂无'}}
        </el-descriptions-item>
		<el-descriptions-item >
		  <template slot="label">收入类型</template>
		  <span v-if="detail.income_type == 1">服务（各类疗程卡项及疗程卡项赠送产品）</span>
		  <span v-if="detail.income_type == 2">产品销售（单独付费购买产品）</span>
		  <span v-if="detail.income_type == 3">医美收入</span>
		</el-descriptions-item>
    <el-descriptions-item >
            <template slot="label">备注</template>
            {{detail.description?detail.description:'暂无'}}
          </el-descriptions-item>
        </el-descriptions>
      </el-descriptions>

    </div>
    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />商品列表
        </div>
      </div>

      <div class="table">
        <!--列表-->
        <el-table
          :data="goodsList"
          size="small"
          border
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
          <el-table-column label="商品名称" prop="goods_name" />
          <el-table-column label="商品名称-别名" prop="goods_alias_name" />
          <ElTableColumn label="服务/商品">
            <template slot-scope="{ row }">
              <div v-if="row.is_project == 1">商品</div>
              <div v-if="row.is_project == 2">服务</div>
            </template>
          </ElTableColumn>
          <el-table-column label="数量" prop="number" />
          <el-table-column label="标准单价" prop="goods_price" />
          <el-table-column label="销售单价" prop="pay_price" />
          <el-table-column label="销售金额" prop="order_goods_price" />
          <el-table-column label="商品/服务类型">
            <template slot-scope="{ row }">
              <div v-if="row.project_type == 1 && row.is_project ==2">按次</div>
              <div v-if="row.project_type == 2 && row.is_project ==2">按天</div>
            </template>
          </el-table-column>
          <el-table-column label="服务">
            <template slot-scope="{ row }">
              <div v-if="row.project_type == 1 ">{{row.service_number}}次</div>
              <div v-if="row.project_type == 2 ">{{row.start_time}}-{{row.end_time}}</div>
            </template>
          </el-table-column>

          <el-table-column label="陪护师一"  prop="nurse_one_name"> </el-table-column>
          <el-table-column label="陪护师一服务小项"  prop="nurse_one_project_text"> </el-table-column>
          <el-table-column label="陪护师二"  prop="nurse_two_name"> </el-table-column>
          <el-table-column label="陪护师二服务小项"  prop="nurse_two_project_text"> </el-table-column>

        </el-table>
         <div class="total">
          <span>订单总额：</span>
          <span class="peril">{{this.detail.total_amount}}</span>
        </div>
      </div>
    </div>

    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />陪护师及服务小项变更记录
        </div>
      </div>

      <div class="table">
        <!--列表-->
        <el-table
          :data="orderGoodsOption"
          size="small"
          border
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >

          <ElTableColumn label="商品名称">
            <template slot-scope="{ row }">
              {{row.goods_name}}
              <span v-if="row.is_new == 1" style="color: red;">(新)</span>
            </template>
          </ElTableColumn>
          <el-table-column label="陪护师一"  prop="nurse_one_name"> </el-table-column>
          <el-table-column label="陪护师一服务小项"  prop="nurse_one_project_text"> </el-table-column>
          <el-table-column label="陪护师二"  prop="nurse_two_name"> </el-table-column>
          <el-table-column label="陪护师二服务小项"  prop="nurse_two_project_text"> </el-table-column>
          <el-table-column label="操作时间"  prop="create_time"> </el-table-column>
        </el-table>

      </div>
    </div>


  </div>
</template>

<script>
import { orderDetail, orderGoodsDetail,checkOrder,orderGoodsOptionDetail } from "@/api/order";
export default {
  data() {
    return {
      detail: {},
      goodsList: [],
      orderGoodsOption:[],
      orderGoodsDetail:[],
      type:'order'
    };
  },
  mounted() {
    this.getDetail();
    this.type = this.$route.query.type
  },
  methods: {
    // 获取详情信息
    getDetail() {
      orderDetail({ order_id: this.$route.query.id }).then(res => {
        this.detail = res;
      });
      orderGoodsDetail({ order_id: this.$route.query.id }).then(res => {
        this.goodsList = res.goodsList;
      });
      orderGoodsOptionDetail({ order_id: this.$route.query.id }).then(res => {
        this.orderGoodsOption = res.list;
      });
    },
     // 提示
    hint(type,text) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if(type == 'del'){
            this.delOrder();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 作废订单
    delOrder(){
      checkOrder({ order_id: this.$route.query.id,status:4}).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          if(this.type != 'order'){
            this.back()
          }else{
            this.$router.go(-1)
          }
      });
    },
    // 修改订单信息
    openDialog(type, row) {
      const query = { detail: this.detail };
      switch (type) {
        case "修改订单":
          this.$router.push({ name: "修改销售订单", query });
          break;
        }
    },
    // 返回
    back() {
        const query = { detail: this.$route.query.detail,id:this.$route.query.detail.id,activeName:'thirdly'}
        this.$router.push({ name: '客户详情',query});

    }
  }
};
</script>

<style lang="scss" scoped>
.conMsg {
  margin-bottom: 30px;
  .top {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 34px;
      color: #666;
    }
  }

  .table {
    border-top: 1px solid #eee;

    .total {
      text-align: right;
      padding: 10px 64px 10px 80px;
      box-sizing: border-box;
      background: #f9fafc;
      border: 1px solid #eee;
      border-top: 0;
      span {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .submit {
    text-align: center;
  }
  .mainBtn {
    margin: 50px 20px;
  }
}
::v-deep .el-descriptions-item__label.is-bordered-label{
  background: #f9fafc;
}
  .return {
  position: fixed;
  right: 20px;
  top: 71px;
  z-index: 999;
  .backImg {
    margin-right: 14px;
    width: 32px;
    height: 100%;
    cursor: pointer;
    vertical-align: middle;
  }
}
</style>
